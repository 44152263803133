<template>
  <ul class="d-flex flex-column justify-space-between text-center pl-0">
    <liNav
      title="Cotisations et tarifs"
      module_name="affiliations__cotisations_et_tarifs"
      auth
    />
    <liNav
      title="Organiser groupes et sections"
      module_name="affiliations__organiser_groupe_et_section"
      auth
    />

    <liNav
      title="Planning des groupes"
      module_name="affiliations__organiser_planning_des_seances"
      auth
    />
  </ul>
</template>

<script>
import liNav from "@/components/Common/Navigation__item";

export default {
  components: { liNav },

  data: () => ({}),
  created() {},

  computed: {},
};
</script>
